@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100svw;
  height: 100svh;
}

body {
  background: #006633;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

body, input, button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #444444;
}

.logo {
  max-height: 50px;
  margin-bottom: 20px;
  /* align-self: flex-start; */
}

.app {
  max-width: 800px;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 16px 16px 16px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.content {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 24px;
  flex-grow: 1;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footerMessage {
  margin-top: 20px;
  text-align: center;
}

.footerMessage span {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: #ffffff;
}

.footerMessage a {
  color: #ffffff;
  font-size: 16px;
  margin-top: 20px;
  text-decoration: none !important;
}

.footerMessage a:hover {
  text-decoration: underline;
  color: #ffcc00;
}

img {
  width: 100%;
  max-width: 400px;
  max-height: 300px;
}

a {
  color: #444;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
  color: #ffcc00;
}

h1 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
}

h2 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
}

button {
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ffcc00;
  color: #444444;
  cursor: pointer;
}

button:hover, button:focus {
  background: #dfb200;
  color: #444;
}

.twoButtons {
  width: 100%;
}

.twoButtons button:first-child {
  margin-bottom: 8px;
  background: #006633;
  color: #fff;
}

table {
  width: 100%;
  max-width: 400px;
  font-size: 14px;
  background-color: #eee;
  border-radius: 0 0 5px 5px;
  padding: 0px 10px 10px 10px;
}

thead, th {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 16px;
}

caption {
  font-size: 16px;
  font-weight: 700;
  background-color: #eee;
  border-radius: 5px 5px 0 0;
  padding: 10px 0px 0px 0px;
}

tfoot {
  font-weight: 700;
  font-size: 16px;
}


tbody>tr>td {
  padding-top: 8px;
}

tfoot>tr>td {
  padding-top: 24px;
}

tr {
  padding-bottom: 30px;
}

h3 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 8px;
}

.summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

textarea {
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* height: 150px; */
  padding: 8px;
  width: 100%;
  flex-grow: 1;
}

p {
  font-size: 14px;
  text-align: center;
  margin: 8px 0 8px 0;
}

.info {
  color: steelblue;
}

.error {
  color: indianred;
}

.quadri {
  text-decoration: none !important;
  color: #444;
}

input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input, select {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
  height: 45px;
  padding: 0 15px;
  font-size: 14px;
  cursor: pointer;
  color: #444;
  width: 100%;
  margin-bottom: 8px;
}

*:disabled {
  background-color: #ddd;
  cursor: auto;
}

label {
  font-size: 14px;
  font-weight: bold;
  color: #444;
  line-height: 30px;
}